import { h } from 'preact';
import { Router } from 'preact-router';

import Header from './header';
import Login from './login';
// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import NewFolder from '../routes/NewCustomer';
import ButtonComponent from './ButtonComponent/ButtonComponent';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';



const App = () => (
	<div id="app">
		<AuthenticatedTemplate>
		<Header />
		<main>
			<Router>
				<Home path="/" />
				<NewFolder path="/NewFolder/:folderType" />
				<Login path="/login" />
			</Router>
		</main>

		</AuthenticatedTemplate>

    <UnauthenticatedTemplate>
    <Login />
    </UnauthenticatedTemplate>
	

	</div>
	
);

export default App;

