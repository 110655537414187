import { useMsal } from '@azure/msal-react';
import style from 'style/homeStyle.css';

const Login = () => {
  const { instance } = useMsal();

  const initializeSignIn = () => {
    instance.loginPopup();
  };

  return (
    <>
    <video autoplay loop muted playsinline class="backgroundVideo">
			<source src="../../assets/rideCORE_Home.mp4" type="video/mp4" />
			Your browser does not support the video tag.
		</video>
    <div class="login">
               <a href="/">
				<img src="../../assets/core-kiteboarding_logo.svg" alt="Core Logo" height="160" width="160" />
			</a>
			<h1>WILLKOMMEN ZUM SHAREPOINT-ORDNER-TOOL VON CORE.</h1>
			<p>Du bist derzeit nicht eingeloggt. Bitte logge dich ein, um dieses Tool nutzen zu können.</p>
      
        <section>
        <a onClick={initializeSignIn} className="resource">
          <h2>EINLOGGEN</h2>
          <p>Mit dem CORE Account (Microsoft Azure AD) einloggen.</p>
        </a>
        </section>

    </div>
    </>
  );
};


export default Login;
