import './style';
import ButtonComponent from './components/ButtonComponent/ButtonComponent';
import App from './components/app';

import { MsalProvider } from "@azure/msal-react";
import { Configuration,  PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId:  "5e82f168-4480-41d5-b708-605fb460801c",
    //authority: "https://login.microsoftonline.com/a2f8d3f4-1544-492f-8115-6fcc332e5505/oauth2/v2.0/authorize",
    authority: "https://login.microsoftonline.com/a2f8d3f4-1544-492f-8115-6fcc332e5505/",
    redirectUri: "/",
    postLogoutRedirectUri: "/login",
  }
}

const pca = new PublicClientApplication(msalConfig);

const AppWithAuthentication = () => (
    <MsalProvider instance={pca}>
        <App />
    </MsalProvider>
);


// create PublicClientApplication instance

// Wrap your app component tree in the MsalProvider component
//render(<AppWithAuthentication />,document.body);
export default AppWithAuthentication;