import { h, Component, useState } from "preact";
import style from "style/homeStyle.css";

class ButtonComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputText: "",
      selectedYear: new Date().getFullYear(),
      button_state: false,
    };
  }

  handleInputChange = (e) => {
    this.setState({ inputText: e.target.value });
  };
  handleYearChange = (e) => {
    this.setState({ selectedYear: e.target.value });
  };
  componentWillReceiveProps(lastProps) {
    console.log("LastPropBtnState: ");
    if (lastProps.folderType !== this.props.folderType) {
      this.setState({ button_state: false });
    }
  }

  getPrefix(newFolderType) {
    let prefixPath = "";
    let isYearNeeded = false;
    if (newFolderType === "Kunden") {
      prefixPath = "Shared Documents/CORE Dateibibliothek/1 Kunden/";
    } else if (newFolderType === "Lieferanten") {
      prefixPath = "Shared Documents/CORE Dateibibliothek/2 Lieferanten/";
    } else if (newFolderType === "Produkt") {
      prefixPath = "Shared Documents/CORE Dateibibliothek/3 Produkte/";
      isYearNeeded = true;
    }
    return [prefixPath, isYearNeeded];
  }

  handleClick = async () => {
    const inputText = this.state.inputText;
    const [prefixPath, isYearNeeded] = this.getPrefix(this.props.folderType);

    let requestData = "";
    if (inputText === "") {
      return null;
    }
    console.log("Button Clicked: " + inputText + " - " + prefixPath);

    this.setState({ button_state: true });

    if (isYearNeeded === false) {
      requestData = prefixPath + inputText;
    } else {
      requestData = prefixPath + this.state.selectedYear + "/" + inputText;
    }

    console.log("Using path: " + requestData);

    const webhookLink =
      "https://c15c62b1-5e27-4bc8-99ee-5b68fb3b93d8.webhook.dewc.azure-automation.net/webhooks?token=VKGCqOIe%2bzLENyGH1A91oFzdFrZ7orAM7OuWKMVAYv8%3d";

    // Send a request to the server to trigger the Python script
    try {
      const response = await fetch(webhookLink, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "no-cors",
        body: JSON.stringify(requestData),
      });
      console.log(response);

      if (response.ok) {
        console.log("Webhook request sent and received ok response.");
        // Handle success
      } else {
        console.error("Webhook request did not return ok response - ERROR");
        // Handle error
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error
    }
  };

  donereturn = () => {
    var now = new Date();
    now.setMinutes(now.getMinutes() + 3);
    var timeString = now.toTimeString().split(" ")[0]; // This will give you just the time part

    return (
      <>
        <div class="loading">
          <p>
            Die Erstellung des Ordners wird verarbeitet. <br />
            <h2>
              BITTE UM <b>{timeString}</b> NACHSCHAUEN, OB DER ORDNER IM
              SHAREPOINT ANGELEGT WURDE.
            </h2>
          </p>
          <div class="loader"></div>
          <p>
            <b>Auf dieser Seite passiert erstmal nix mehr.</b> Der Unterordner
            sollte in ein/zwei Minuten angelegt sein.
          </p>
        </div>
      </>
    );
  };

  render() {
    const outVal = `${this.props.folderType}namen eingeben`;
    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 2;
    const btnState = this.state.button_state;

    console.log("-buttonState: ", btnState, " - ", this.state.button_state);

    const [prefixPath, isYearNeeded] = this.getPrefix(this.props.folderType);
    //console.log("Year needed: ", isYearNeeded, " - prefixpath: ", prefixPath)

    const btnreturn = (
      <>
        <div class="cont">
          <input
            type="text"
            placeholder={outVal}
            value={this.state.inputText}
            onInput={this.handleInputChange}
          />
          {isYearNeeded && (
            <div>
              <label for="yearSelect">Select a year: </label>
              <select
                id="yearSelect"
                onChange={this.handleYearChange}
                value={this.state.selectedYear}
              >
                {Array.from({ length: nextYear - 2018 + 5 }, (_, index) => {
                  const year = 2018 + index;
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          <br />
          <button onClick={this.handleClick} disabled={btnState}>
            {this.props.folderType}ordner anlegen
          </button>
        </div>
      </>
    );

    if (btnState === false) {
      return btnreturn;
    } else {
      return this.donereturn();
    }
  }
}

export default ButtonComponent;
