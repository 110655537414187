import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from 'style/headerStyle.css'; // Import your CSS module
import { useMsal } from '@azure/msal-react';



const Header = () => {
  const { instance, accounts } = useMsal();
  //console.log(accounts[0])
  //{accounts[0]?.username} email.
  const initializeSignOut = () => {
      instance.logoutRedirect();
    };
    return (
  <header className="header">
    <a href="/" className="logo">
      <img src="../../assets/core-kiteboarding_logo.svg" alt="CORE Logo" height="45" width="130" />
      <h1>SHAREPOINT-ORDNER ANLEGEN</h1>
    </a>
    <input type="checkbox" id="menu-toggle" class="menuToggle" />
    <label for="menu-toggle" class="menuIcon">
      <div class="bar bar1"></div>
      <div class="bar bar2"></div>
      <div class="bar bar3"></div>
    </label>
    <nav class="nav">
      <Link activeClassName="active" href="/">
        HOME
      </Link>
      <Link activeClassName="active" href="/NewFolder/customer">
        NEUER KUNDENORDNER
      </Link>
      <Link activeClassName="active" href="/NewFolder/supplier">
        NEUER LIEFERANTENORDNER
      </Link>
      <Link activeClassName="active" href="/NewFolder/product">
        NEUER PRODUKTORDNER
      </Link>
      <Link  href="" onClick={initializeSignOut}> - LOGOUT {accounts[0]?.name.toUpperCase()} -</Link>
    </nav>
  </header>
    );
    };

export default Header;
